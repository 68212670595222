.logo_wrapper {
  height: 100vh;
  width: 100vw;
  align-items: center;
  justify-content: center;
}

.screen {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

body {
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.screen {
  /* background-color: #55198b; */
  overflow: hidden;
  border-radius: 100%;
  animation: grow 4s forwards;
}

@keyframes grow {
  0% {
    transform: scale(0);
  }
  10% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  90% {
    transform: scale(1);
    border-radius: 0%;
    height: 100%;
    width: 100%;
  }
  100% {
    transform: scale(0);
    transform-origin: 50% 50%;
    border-radius: 100%;
  }
}

#logo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
